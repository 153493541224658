<template>
    <div id="smartDisplay" v-loading="loading"  :element-loading-text="$t('alarm.a0')">
        <!-- <a-empty v-else></a-empty> -->
        <iframe class="f" v-if="url!=='#'" id="display-iframe" :src="url"></iframe>
    </div>
</template>
<script>
import { getDisplayToken } from "../../api/board";
import moment from "moment";
export default {
    data(){
        return {
            loading:false,
            key:'',
            token:undefined,
            theme:'',
            board:{clientId:0,sitegrId:0,key:'',deviceId:0,model:[],token:undefined,title:'',flushTime:'',theme:''},
            text:'',
            html:'',
            url:'#',

        }
    },
    components: {
    },
    mounted(){
        console.log('key',this.$route.query.key);
        this.key = this.$route.query.key;
        this.loadFrame();        
    },
    methods:{
        loadFrame(){
            // this.loading = true;
            // getDisplayToken(this.key)
            // .then((res) => {
            //     let { data,errorCode,msg } = res;
            //     console.log(data);
            //     if(errorCode==='00'){
            //         this.loading = false;
            //         const {key,deviceId,deviceIp,devicePort,devicePath} = data;

                   
            //         let url = window.location.origin+"/"+this.key;
            //         window.location = url;//'https://www.bivale.cn/';
            //     } else {
            //         this.loading = false;
            //         this.$message.error(msg);
            //     }
            // })
            // .catch((err) => {
            //     this.loading = false;
            //     console.log("错了",err);
            // });
            // let url = window.location.origin+"/"+this.key;
            window.location = url;//'https://www.bivale.cn/';
            // this.url = 'http://10.98.233.3:8080/#/';
            
            //

            
            // let iframeWindow = document.querySelector("#display-iframe").contentWindow;
        	// let config = {
            //         text: this.text
        	// };
        	// iframeWindow.setConfig(config);
        	// this.html = iframeWindow.getHtml(`window.baseConfig = ${JSON.stringify(config)};`);
        },
    }
}
</script>

<style scoped>
#smartDisplay{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.f{
    width: 100%;
    height: 100%;
    border: none;
}
</style>